$c_primary: #56BB6D;
$c_primary_dark: #3ABB6D;
$c_secondary: #081630;
$c_secondary_light: #0F1E39;
$c_heading: #081630;
$c_text: #081630;
$c_danger: #d92d20;
$c_warning: #F29423;
$c_success: #079455;
$c_black: #000;
$c_white: #fff;
$light_gray:#f5f5f5;
$f_body: 'Urbanist', sans-serif;
$transition: all 0.3s ease-in-out 0s;
$shadow: 0px 14px 64px -4px rgba(45, 115, 227, 0.12), 0px 8px 22px -6px rgba(45, 115, 227, 0.12);

@mixin content_limit($line: 1) {
    white-space: normal;
    display: -webkit-box !important;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@mixin d-flex($align:center, $justy:space-between,$gap:0,$wrap:wrap){
    display:flex;
    align-items:$align;
    justify-content: $justy;
    gap:$gap;
    flex-wrap: $wrap;
} 

// Media queries
@mixin breakpoint($point) {
    @if $point==desktopLarge {
        @media only screen and (min-width: 1400px) {
            @content;
        }
    }

    @else if $point==desktop {
        @media only screen and (max-width: 1399.98px) {
            @content;
        }
    }

    @else if $point==ipadLandsacpe {
        @media only screen and (max-width: 1199.98px) {
            @content;
        }
    }

    @else if $point==ipad {
        @media only screen and (max-width: 991.98px) {
            @content;
        }
    }

    @else if $point==mobile {
        @media only screen and (max-width: 767.98px) {
            @content;
        }
    }

    @else if $point==mobileSmall {
        @media only screen and (max-width: 575.98px) {
            @content;
        }
    }

    @else if $point==ipadLarge {
        @media only screen and (min-width: 768px) {
            @content;
        }
    }
}