@import "../../variable.scss";

.auth_section {
  padding: 30px 0;
  background-image: linear-gradient(270deg, #61c077 0%, #ffffff 100%);

  .gap_p {
    align-items: flex-start;
  }

  :is(.left_side, .right_side) {
    width: 50%;

    @include breakpoint(ipad) {
      width: 100%;
    }
  }

  .left_side {
    padding-top: 50px;
    // position: sticky;
    // top: 160px;

    @include breakpoint(ipad) {
      display: none;
    }

    figure {
      display: inline-flex;

      @include breakpoint(ipadLandsacpe) {
        max-width: 400px;
      }
    }

    h1 {
      margin-bottom: 40px;

      @include breakpoint(desktop) {
        margin-bottom: 30px;
      }
    }
  }

  .form {
    max-width: 475px;
    margin-left: auto;
    padding: 60px 30px;
    background-image: linear-gradient(90deg, #c2fed0 0%, #8feaa4 100%);
    border-radius: 30px;

    @include breakpoint(ipadLandsacpe) {
      max-width: 420px;
      padding: 30px 25px 35px;
      border-radius: 10px;
    }

    @include breakpoint(ipad) {
      margin: 0 auto;
    }

    @include breakpoint(mobile) {
      padding: 20px 15px 25px;
      border-radius: 5px;
    }

    h2 {
      color: #393939;
      font-weight: 900;
      margin-bottom: 20px;
    }

    &_control {
      &.d_flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &_content {
        margin-bottom: 15px;

        p {
          font-size: 14px;
          font-weight: 500;

          b {
            font-weight: 800;
          }

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
      }

      .text_end {
        // margin-top: -20px;

        @include breakpoint(mobile) {
          margin-top: -15px;
        }
      }
    }

    .anchor_link {
      color: $c_text;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.2;
      display: inline-flex;
      transition: $transition;
      cursor: pointer;

      &:hover {
        text-decoration: underline !important;
      }
    }

    &_btn {
      .btn,
      .btn.MuiButton-root {
        font-size: 20px;
        font-weight: 700;
        min-height: 50px;
        min-width: 155px;
        text-transform: uppercase;

        @include breakpoint(ipadLandsacpe) {
          font-size: 18px;
          min-height: 50px;
          min-width: 150px;
        }

        @include breakpoint(mobile) {
          font-size: 16px;
          min-height: 45px;
          min-width: 130px;
        }
      }
    }

    &_bottom {
      margin-top: 25px;

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;

        a {
          color: $c_primary;
          font-weight: 700;

          &:hover {
            text-decoration: underline !important;
          }
        }
      }
    }

    .MuiFormControl-root,
    .MuiTextField-root,
    .react-tel-input {
      > .MuiInputBase {
        &-root {
          position: relative;
          padding: 0;

          &::before,
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .profile_flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .btn {
      font-size: 12px;
      min-height: 40px;
      font-weight: 600;
      padding: 5px 10px;
      min-width: 1px;
      gap: 5px;
    }
  }
}

.remember_box {
  display: flex;
  align-items: center;

  .text_lab,
  label .MuiFormControlLabel-label {
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    line-height: 1.2;
    font-style: normal;
    font-family: $f_body;
    &.v2 {
      font-size: 18px;

      a {
        &:hover {
          text-decoration: underline !important;
        }
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 16px;
      }

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }
    a {
      color: $c_primary;
      text-decoration: underline !important;
      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  label {
    margin: 0 !important;

    .MuiCheckbox-root {
      padding: 0;
      position: relative;
      top: -1px;
      left: -2px;
      margin-right: 2px;

      input {
        height: 100%;
      }

      svg {
        height: 20px;
        width: 20px;

        path {
          fill: $c_primary;
        }
      }
    }
  }
}
