@import "../../variable.scss";

.faq_sc {
    .MuiAccordion-root {
        max-width: 1040px;
        margin: 0 auto 20px;
        background-color: #F8F8F8;
        border: 1px solid #EEEEEE;
        border-radius: 6px;

        @include breakpoint(mobile) {
            border-radius: 5px;
            margin: 0 auto 15px;
        }

        &::before {
            display: none;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .MuiAccordionSummary-root {
            gap: 10px;
            padding: 10px 15px;

            @include breakpoint(mobile) {
                gap: 5px;
            }

            .MuiAccordionSummary-content {
                margin: 0;
            }

            p {
                color: #393939;
                font-size: 16px;
                font-weight: 600;

                @include breakpoint(mobile) {
                    font-size: 14px;
                    font-weight: 700;
                }
            }
        }

        .MuiAccordionDetails-root {
            padding: 15px;
            border-top: 1px solid #EEEEEE;

            p {
                color: #393939;
                font-size: 16px;
                font-weight: 600;

                @include breakpoint(mobile) {
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}