@import "../../variable.scss";

.account_sc {
  .left_s {
    max-width: calc(305px + var(--gap_x));
    min-width: calc(305px + var(--gap_x));

    @include breakpoint(desktop) {
      max-width: calc(280px + var(--gap_x));
      min-width: calc(280px + var(--gap_x));
    }

    @include breakpoint(ipadLandsacpe) {
      max-width: 100%;
      min-width: 100%;
    }
  }

  .right_s {
    width: calc(100% - (305px + var(--gap_x)));

    @include breakpoint(desktop) {
      width: calc(100% - (280px + var(--gap_x)));
    }

    @include breakpoint(ipadLandsacpe) {
      width: 100%;
    }
  }
}

.account {
  &_sidebar {
    padding: 0 20px 25px;
    background: $c_white;
    box-shadow: 0px 2px 66px #0000001a;
    border-radius: 20px;
    position: sticky;
    top: 90px;
    max-height: calc(100vh - 110px);
    overflow: auto;

    @include breakpoint(desktop) {
      padding: 0 15px 15px;
    }

    @include breakpoint(ipadLandsacpe) {
      position: initial;
      max-height: initial;
      border-radius: 15px;
    }

    @include breakpoint(ipad) {
      border-radius: 10px;
    }

    @include breakpoint(mobile) {
      border-radius: 0;
      margin-bottom: 20px;
      background: transparent;
      overflow: visible;
      box-shadow: none;
      padding: 0;
    }
  }

  &_profile {
    display: flex;
    align-items: center;
    margin: 0 -20px;
    padding: 20px;
    gap: 20px;
    position: sticky;
    top: 0;
    left: 0;
    background-color: $c_white;
    z-index: 1;

    @include breakpoint(desktop) {
      margin: 0 -15px;
      padding: 15px;
      gap: 15px;
    }

    @include breakpoint(mobile) {
      gap: 10px;
      margin: 0;
      border-radius: 5px;
      box-shadow: 0px 2px 66px #0000001a;
    }

    figure {
      max-width: 80px;
      min-width: 80px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      overflow: hidden;

      @include breakpoint(desktop) {
        max-width: 70px;
        min-width: 70px;
      }

      @include breakpoint(mobile) {
        max-width: 60px;
        min-width: 60px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    h2 {
      color: $c_black;
      font-size: 16px;
      margin-bottom: 5px;
    }

    p {
      color: $c_black;
      font-size: 14px;
      line-height: 1.2;
      word-break: break-word;
    }
  }

  &_listing {
    padding: 0 !important;
    margin: 0 -10px !important;

    @include breakpoint(ipadLandsacpe) {
      display: flex;
      flex-wrap: wrap;
      gap: 6px;
      margin: 0 !important;

      &::-webkit-scrollbar {
        height: 4px;
      }
    }

    @include breakpoint(mobile) {
      gap: 5px;
      padding: 10px 15px !important;
      margin: 0px -15px !important;
      background-color: $c_white;
      box-shadow: 0px 2px 66px #0000001a;
      position: fixed !important;
      flex-wrap: nowrap;
      overflow: auto;
      width: 100%;
      top: 104px;
      z-index: 5;
    }

    li {
      padding: 0;

      @include breakpoint(ipadLandsacpe) {
        width: auto;
      }

      &:not(:last-child) {
        margin-bottom: 0px;
      }

      .MuiListItemButton-root {
        color: $c_black;
        padding: 10px;

        @include breakpoint(desktop) {
          padding: 8px 10px;
        }

        @include breakpoint(ipadLandsacpe) {
          border: 1px solid rgba(0, 0, 0, 0.1);
          padding: 10px 12px;
        }

        @include breakpoint(mobile) {
          padding: 5px 8px;
          border-radius: 3px;
          min-height: 35px;
          display: inline-flex;
          align-items: center;
        }

        .MuiListItemText-root {
          margin: 0;

          .MuiTypography-root {
            font-size: 18px;
            font-weight: 600;
            font-family: $f_body;
            line-height: 1.25;
            display: flex;
            width: 100%;

            @include breakpoint(desktop) {
              font-size: 16px;
            }

            @include breakpoint(ipadLandsacpe) {
              font-size: 14px;
            }

            @include breakpoint(mobile) {
              font-size: 12px;
              white-space: nowrap;
            }
          }
        }

        &.Mui-selected {
          color: $c_primary;
          background-color: transparent;

          @include breakpoint(ipadLandsacpe) {
            color: $c_white;
            background-color: $c_primary;
            border-color: $c_primary;
          }
        }
      }
    }
  }

  &_detail {
    padding: 40px;
    background: $c_white;
    box-shadow: 0px 13px 25px #00000012;
    border-radius: 20px;

    @include breakpoint(desktop) {
      padding: 30px;
    }

    @include breakpoint(ipadLandsacpe) {
      padding: 20px;
      border-radius: 15px;
    }

    @include breakpoint(ipad) {
      border-radius: 10px;
    }

    @include breakpoint(mobile) {
      padding: 0;
      border-radius: 5px;
      box-shadow: none;
    }

    .head_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include breakpoint(mobileSmall) {
        flex-direction: column;
        align-items: flex-start;
        gap: 15px;
      }

      .btn {
        font-size: 16px;
        min-height: 45px;
        min-width: 1px;
      }
    }

    .custom_tabs {
      padding-top: 30px;

      @include breakpoint(desktop) {
        padding-top: 20px;
      }

      &.pt_0 {
        padding-top: 0;
      }

      .custom_tabs_links {
        .MuiTabs-scroller {
          padding: 0 0 30px;
          text-align: center;

          @include breakpoint(desktop) {
            padding: 0 0 20px;
          }

          @include breakpoint(mobile) {
            padding: 0 0 10px;
          }
        }

        .MuiTabs-flexContainer button {
          min-height: 60px;

          @include breakpoint(desktop) {
            min-height: 50px;
          }

          @include breakpoint(mobile) {
            min-height: 45px;
          }
        }
      }

      &1 {
        .custom_tabs_links {
          margin-bottom: 30px;

          @include breakpoint(mobileSmall) {
            margin-bottom: 25px;
          }

          .MuiTabs-scroller {
            padding: 0;
            text-align: left;
            display: flex;
          }

          .MuiTabs-flexContainer button {
            min-height: 1px;
          }
        }
      }

      .head_flex {
        // margin-bottom: 25px;
      }
    }

    .subcategory_listing {
      .btn_flex {
        justify-content: center;
        margin-top: 50px;

        @include breakpoint(ipad) {
          margin-top: 40px;
        }

        @include breakpoint(mobile) {
          margin-top: 30px;
        }
      }
    }

    .subcategory_listing_single {
      width: calc(100% / 3 - var(--gap_x));
      transition: none;

      @include breakpoint(mobile) {
        width: calc(100% / 2 - var(--gap_x));
      }

      h3 {
        font-size: 18px;

        @include breakpoint(mobile) {
          font-size: 16px;
        }

        @include breakpoint(mobileSmall) {
          font-size: 14px;
        }
      }

      figure {
        transition: none;

        img {
          transition: none;
          transform: none;
        }
      }

      :is(h3, p) {
        transition: none;
      }
    }

    .form {
      margin-top: 25px;

      @include breakpoint(mobile) {
        >.gap_p {
          flex-direction: column-reverse;
        }
      }

      .w_67 {
        width: 67%;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }

      .w_33 {
        width: 33%;

        @include breakpoint(mobile) {
          width: 100%;
        }
      }

      .gap_p {
        .form_control {
          max-width: 535px;

          >.form_control {
            margin-bottom: 0;
          }

          label {
            color: #c7c7c7;
            font-weight: 700;
            margin-bottom: 5px;
          }

          :is(input, select, .MuiSelect-select, textarea):not(:focus) {
            background-color: #f5f5f5;
          }

          .float_switch {
            position: absolute;
            right: 15px;
            top: 15px;
            transform: scale(0.75);
            z-index: 1;

            +.MuiFormControl-root {
              input {
                padding-right: 65px;
              }
            }
          }
        }
      }

      >.form_control {
        label {
          font-weight: 600;
        }

        :is(input, select, .MuiSelect-select, textarea):not(:focus) {
          border-color: #d3d3d3;
        }

        &.text_end {
          margin-top: -10px;
          text-align: right;

          a {
            font-weight: 600;
          }
        }
      }

      .wallet_btn {
        font-size: 16px;
      }
    }

    .no_date_sc {
      padding-top: 35px;
      padding-bottom: 20px;

      @include breakpoint(mobile) {
        padding-top: 15px;
        padding-bottom: 0px;
      }
    }
  }
}

.wallet_listing {
  li {
    margin: 0;
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid #e3e3e3;

    @include breakpoint(ipad) {
      padding-top: 20px;
      margin-top: 20px;
    }

    @include breakpoint(mobile) {
      padding-top: 15px;
      margin-top: 15px;
    }

    p {
      color: #afafaf;
      font-size: 20px;
      font-weight: 700;
      line-height: 1.2;
      margin-bottom: 10px;

      @include breakpoint(desktop) {
        font-size: 18px;
        margin-bottom: 5px;
      }

      @include breakpoint(ipad) {
        font-size: 16px;
      }

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }

    .d_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;

      .btn {
        @include breakpoint(mobile) {
          min-width: 1px;
        }
      }
    }

    .inner {
      padding-top: 10px;

      .d_flex {
        &:not(:last-child) {
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #e3e3e3;

          @include breakpoint(mobile) {
            margin-bottom: 10px;
            padding-bottom: 10px;
          }
        }
      }

      p {
        color: $c_black;
        margin: 0;
      }
    }
  }
}

.socialMedia_list {
  margin-top: 20px;

  .single {
    padding: 25px;
    border-radius: 20px;
    border: 1px solid #dedede;
    position: relative;

    @include breakpoint(ipadLandsacpe) {
      padding: 20px;
      border-radius: 15px;
    }

    @include breakpoint(ipad) {
      border-radius: 10px;
    }

    @include breakpoint(mobile) {
      padding: 20px 15px;
      border-radius: 5px;
    }

    :is(.action_btn, .action_btn2) {
      position: absolute;
      top: 14px;
      border: none;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $c_white;
      background-color: $c_primary;
      cursor: pointer;

      svg {
        font-size: 16px;
      }

      &:has([data-testid="DeleteIcon"]) {
        background-color: $c_danger;
      }
    }

    .action_btn {
      right: 60px;
    }

    .action_btn2 {
      right: 20px;
    }

    .MuiSwitch-root {
      top: 20px;
      right: 20px;
      position: absolute;

      @include breakpoint(mobile) {
        top: 10px;
        right: 10px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    ul {
      li {
        display: flex;
        gap: 20px;

        @include breakpoint(mobile) {
          flex-direction: column;
          gap: 3px;
        }

        p {
          font-size: 16px;
          font-weight: 500;
          margin: 0;

          @include breakpoint(mobile) {
            font-size: 14px;
          }

          &:first-child {
            max-width: 260px;
            min-width: 260px;

            @include breakpoint(mobile) {
              max-width: 100%;
              min-width: 100%;
              font-weight: 700;
            }
          }
        }
      }
    }
  }
}

.activities_list {
  margin-top: 30px;

  h4 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 30px;

    @include breakpoint(desktop) {
      font-size: 22px;
      margin-bottom: 20px;
    }

    @include breakpoint(ipad) {
      font-size: 20px;
    }

    @include breakpoint(mobile) {
      font-size: 18px;
    }

    &:not(:first-child) {
      margin-top: 50px;

      @include breakpoint(ipad) {
        margin-top: 40px;
      }
    }
  }

  ul {
    li {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: #fcfcfc;
      transition: $transition;
      padding: 12px 15px;
      gap: 20px;

      &:not(:last-child) {
        @include breakpoint(mobileSmall) {
          margin-bottom: 10px;
        }
      }

      figure {
        width: 78px;
        min-width: 78px;
        height: 54px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(desktop) {
          width: 70px;
          min-width: 70px;
          height: 50px;
        }

        @include breakpoint(ipad) {
          width: 50px;
          min-width: 50px;
          height: 30px;
        }

        @include breakpoint(mobileSmall) {
          width: 30px;
          min-width: 30px;
          height: 22px;
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      p {
        flex: 1;
        font-size: 20px;

        @include breakpoint(desktop) {
          font-size: 18px;
        }

        @include breakpoint(ipad) {
          font-size: 16px;
          font-weight: 500;
          gap: 10px;
        }

        @include breakpoint(mobile) {
          font-size: 14px;

          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }

        strong {
          color: $c_black;
          font-weight: 600;
          display: inline-flex;
          gap: 20px;

          @include breakpoint(ipad) {
            gap: 10px;
          }

          @include breakpoint(mobile) {
            gap: 5px;
          }

          span {
            color: #d3d3d3;
            font-size: 16px;
            align-self: center;

            @include breakpoint(mobile) {
              font-size: 14px;
            }
          }
        }

        >span {
          display: block;
        }
      }

      &:hover {
        background-color: #eaefe8;
      }
    }
  }
}

.coupons_listing {
  margin-top: 30px;

  .coupon_card_dtl {
    width: calc(100% / 3 - var(--gap_y));
    min-height: 140px;
    padding: 10px 0;
    border-radius: 10px;
    max-width: 100%;
    cursor: pointer;

    @include breakpoint(ipadLandsacpe) {
      width: calc(100% / 2 - var(--gap_y));
    }

    @include breakpoint(mobileSmall) {
      width: calc(100% / 1 - var(--gap_y));
    }

    >figure,
    .coupon_btn {
      min-width: 60px;
      max-width: 60px;

      &::after,
      &::before {
        inset: -17px -7px auto auto;
        width: 12px;
        height: 12px;
      }

      &::before {
        bottom: -17px;
        top: unset;
      }
    }

    .coupon_dtl {
      padding-inline: 10px;
      width: calc(100% - 120px);

      .uploaded_coupon {
        height: 120px;
      }

      h2 {
        font-size: 24px;
        margin-bottom: 5px;
      }

      p {
        font-size: 12px;
      }
    }

    .coupon_btn {

      &::after,
      &::before {
        left: -7px;
      }

      .btn {
        font-size: 20px;

        @include breakpoint(ipadLandsacpe) {
          font-size: 18px;
        }

        @include breakpoint(mobile) {
          font-size: 16px;
        }
      }
    }
  }
}

.site_header {
  &.scrolled {
    ~.page_account {
      .account_listing {
        @include breakpoint(mobile) {
          top: 54px;
        }
      }
    }
  }
}

.page_account {
  @include breakpoint(mobile) {
    padding-top: 50px;
  }
}