@import "../../variable.scss";

.nftDetail {
  &_sc {
    .gap_p {
      --gap_x: 70px;

      @include breakpoint(desktop) {
        --gap_x: 40px;
      }

      @include breakpoint(ipadLandsacpe) {
        --gap_x: 30px;
      }

      @include breakpoint(ipad) {
        --gap_x: 20px;
      }

      @include breakpoint(mobile) {
        --gap_x: 15px;
      }
    }

    .left_s {
      width: 59%;

      @include breakpoint(desktop) {
        width: 55%;
      }

      @include breakpoint(ipad) {
        width: 100%;
      }
    }

    .right_s {
      width: 41%;

      @include breakpoint(desktop) {
        width: 45%;
      }

      @include breakpoint(ipad) {
        width: 100%;
      }

      :is(p, li, ul) {
        font-size: 20px;

        @include breakpoint(desktop) {
          font-size: 18px;
          font-weight: 800;
        }

        @include breakpoint(ipadLandsacpe) {
          font-size: 16px;
        }

        @include breakpoint(mobile) {
          font-size: 14px;
        }

        &:not(:last-child) {
          margin-bottom: 20px;

          @include breakpoint(desktop) {
            margin-bottom: 16px;
          }
        }
      }

      .cat_group {
        a {
          color: $c_text;
          display: inline-flex;
          &:not(:last-child)::after {
            margin: 0 15px;
            display: inline-flex;
            color: $c_text;
          }
          &:hover {
            color: $c_primary;
          }
        }
      }

      .p_group {
        &:not(:last-child) {
          margin-bottom: 20px;

          @include breakpoint(desktop) {
            margin-bottom: 16px;
          }
        }

        p {
          &:not(:last-child) {
            margin-bottom: 10px;

            @include breakpoint(mobile) {
              margin-bottom: 5px;
            }
          }

          b {
            @include breakpoint(mobileSmall) {
              font-weight: 700;
            }
          }
        }
      }

      .hd_3 {
        margin-block: 20px;

        @include breakpoint(desktop) {
          margin-block: 15px;
        }
      }

      .hd_4 {
        padding-top: 10px;

        p {
          margin-bottom: 0;
        }

        h3 {
          display: flex;
          align-items: center;
          font-weight: 800;
          gap: 8px;

          span {
            font-size: 130%;
          }
        }
      }

      .hd_5 {
        margin-bottom: 20px;

        h3 {
          font-weight: 600;
        }
      }

      p {
        &.links {
          gap: 5px;
          display: flex;
          white-space: nowrap;
          font-weight: 600;

          span {
            font-weight: 400;
            color: $c_primary;
            white-space: nowrap;
            display: inline-block;
            text-overflow: ellipsis;
            overflow: hidden;
            cursor: pointer;
          }

          figure {
            display: inline-flex;
            position: relative;
            top: 1px;

            img {
              height: 26px;
              cursor: pointer;
            }

            span {
              position: absolute;
              left: 50%;
              top: calc(100% + 3px);
              font-size: 13px;
              font-weight: 400;
              background-color: $c_white;
              white-space: nowrap;
              padding: 6px 10px;
              border-radius: 3px;
              transform: translateX(-50%);
              box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            }
          }
        }

        &.sale {
          color: #121212;
          font-weight: 500;
        }

        &.fees {
          font-weight: 500;

          b {
            display: block;
            font-weight: 600;
            margin-bottom: 5px;
          }
        }
      }

      .favs_views {
        display: flex;
        flex-wrap: wrap;
        gap: 10px 30px;

        @include breakpoint(desktop) {
          gap: 10px 25px;
        }

        li {
          font-weight: 500;
          display: inline-flex;
          align-items: center;
          margin: 0;
          gap: 10px;

          @include breakpoint(desktop) {
            gap: 5px;
          }

          svg {
            font-size: 28px;
            color: $c_primary;

            @include breakpoint(desktop) {
              font-size: 22px;
            }
          }
        }
      }

      .btn_flex {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-top: 30px;

        @include breakpoint(ipadLandsacpe) {
          gap: 20px;
        }

        @include breakpoint(mobile) {
          gap: 10px;
          margin-top: 20px;
        }

        .btn {
          width: 100%;
          min-width: 1px;
        }
      }

      .detail_box {
        padding: 20px 15px;
        border: 1px solid #d3d3d3;
        border-radius: 6px;
        margin-top: 25px;

        @include breakpoint(mobile) {
          padding: 15px 10px;
          border-radius: 5px;
          margin-top: 20px;
        }

        &.v2 {
          padding: 25px 25px;
          background-color: $c_white;
          box-shadow: 0px 2px 66px #0000001a;
          border-radius: 20px;
          margin-top: 40px;
          border: none;

          @include breakpoint(desktop) {
            padding: 25px 20px;
            border-radius: 15px;
            margin-top: 35px;
          }

          @include breakpoint(ipadLandsacpe) {
            padding: 20px 15px;
            border-radius: 10px;
            margin-top: 30px;
          }

          @include breakpoint(mobile) {
            padding: 15px 10px;
            border-radius: 5px;
            margin-top: 20px;
          }
        }

        h4 {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 15px;

          @include breakpoint(ipad) {
            margin-bottom: 10px;
          }

          @include breakpoint(mobile) {
            font-size: 16px;
          }
        }

        > p {
          font-size: 16px;
          font-weight: 500;

          @include breakpoint(mobile) {
            font-size: 14px;
          }
        }

        .gap_m {
          --gap_x: 15px;
          --gap_y: 15px;
        }

        .single {
          padding: 15px 10px;
          width: calc(100% / 3 - var(--gap_x));
          background-color: #f2f2f2;
          text-align: center;
          border-radius: 8px;
          min-height: 98px;

          p {
            font-size: 14px;

            &:nth-child(2) {
              word-break: break-word;
              @include content_limit(2);
            }

            &:not(:last-child) {
              margin-bottom: 5px;
            }

            b {
              font-weight: 600;
            }
          }
        }

        .table_responsive {
          overflow: auto;
        }

        table {
          width: 100%;
          border-collapse: collapse;

          th,
          td {
            padding: 8px 15px;
            line-height: 1.2;

            @include breakpoint(ipadLandsacpe) {
              padding: 6px 15px;
            }

            @include breakpoint(mobile) {
              padding: 6px 10px;
            }

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }

          thead {
            tr {
              th {
                font-size: 20px;
                font-weight: 600;
                text-align: left;
                padding-top: 0;

                @include breakpoint(ipadLandsacpe) {
                  font-size: 18px;
                }

                @include breakpoint(mobile) {
                  font-size: 16px;
                }
              }
            }
          }

          tbody {
            tr {
              &:last-child {
                td {
                  padding-bottom: 0;
                }
              }

              td {
                font-size: 16px;
                font-weight: 500;
                text-align: left;

                @include breakpoint(ipadLandsacpe) {
                  font-size: 15px;
                }

                @include breakpoint(mobile) {
                  font-size: 14px;
                }

                svg {
                  cursor: pointer;
                  color: red;

                  &:first-child {
                    color: green;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }

      .custom_tabs2 {
        margin-top: 35px;

        @include breakpoint(desktop) {
          margin-top: 25px;
        }

        .custom_tabs_links {
          margin-bottom: 30px;

          @include breakpoint(desktop) {
            margin-bottom: 20px;
          }

          @include breakpoint(ipad) {
            margin-bottom: 15px;
          }
        }

        .hd_4 {
          padding-top: 0;
        }

        .form_control {
          :is(input, select, .MuiSelect-select, textarea):not(:focus) {
            border-color: #d3d3d3;
          }
        }
      }

      .nft_desc {
        display: none;

        @include breakpoint(ipad) {
          display: block;
          margin-block: 25px 20px;
        }

        @include breakpoint(mobile) {
          margin-block: 20px;
        }

        .hd_3 {
          margin-block: 30px 15px;

          @include breakpoint(desktop) {
            margin-block: 25px 20px;
          }

          @include breakpoint(ipadLandsacpe) {
            margin-block: 20px 15px;
          }

          @include breakpoint(ipad) {
            margin-block: 0 10px;
          }

          h2 {
            @include breakpoint(ipad) {
              font-size: 20px;
            }

            @include breakpoint(mobile) {
              font-size: 18px;
            }
          }
        }

        h3 {
          color: #121212;
          font-size: 24px;
          margin-bottom: 10px;

          @include breakpoint(desktop) {
            font-size: 22px;
          }

          @include breakpoint(ipadLandsacpe) {
            font-size: 20px;
          }

          @include breakpoint(ipad) {
            font-size: 18px;
          }

          @include breakpoint(mobile) {
            font-size: 14px;
            margin-bottom: 7px;
          }

          &:empty {
            display: none;
          }
        }

        p {
          font-size: 20px;

          @include breakpoint(desktop) {
            font-size: 18px;
          }

          @include breakpoint(ipadLandsacpe) {
            font-size: 16px;
          }

          @include breakpoint(mobile) {
            font-size: 14px;
          }
        }
      }
    }
  }

  &_content {
    .nft_desc {
      @include breakpoint(ipad) {
        display: none;
      }
    }

    > figure {
      width: 100%;
      display: flex;
      margin-bottom: 15px;

      img {
        width: 100%;
        aspect-ratio: 1/0.7;
        border: 1px solid rgba(0, 0, 0, 0.05);
        object-fit: contain;
        border-radius: 10px;
      }
    }

    .credit_tag {
      color: $c_white;
      font-size: 16px;
      display: inline-flex;
      align-items: center;
      padding: 10px 20px;
      background-color: $c_secondary;
      border-radius: 6px;

      @include breakpoint(ipadLandsacpe) {
        font-size: 14px;
        padding: 8px 15px;
        border-radius: 3px;
      }

      @include breakpoint(ipad) {
        padding: 8px 10px;
      }

      @include breakpoint(mobile) {
        font-size: 12px;
      }
    }

    .hd_3 {
      margin-block: 30px 15px;

      @include breakpoint(desktop) {
        margin-block: 25px 20px;
      }

      @include breakpoint(ipadLandsacpe) {
        margin-block: 20px 15px;
      }

      @include breakpoint(ipad) {
        margin-block: 20px 10px;
      }

      h2 {
        @include breakpoint(ipad) {
          font-size: 20px;
        }
      }
    }

    h3 {
      color: #121212;
      font-size: 24px;
      margin-bottom: 10px;

      @include breakpoint(desktop) {
        font-size: 22px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 20px;
      }

      @include breakpoint(ipad) {
        font-size: 18px;
      }

      &:empty {
        display: none;
      }
    }

    p {
      font-size: 20px;

      @include breakpoint(desktop) {
        font-size: 18px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 16px;
      }

      @include breakpoint(mobile) {
        font-size: 14px;
      }
    }
  }
}
