@import "../../variable.scss";

.cms_content {
    h1 {
        font-size: 40px;
        margin-bottom: 20px;

        @include breakpoint(ipadLandsacpe) {
            font-size: 36px;
        }

        @include breakpoint(ipad) {
            font-size: 32px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 15px;
        }
    }

    h2 {
        font-size: 26px;
        margin-block: 35px 16px;

        @include breakpoint(ipadLandsacpe) {
            font-size: 24px;
        }

        @include breakpoint(ipad) {
            font-size: 22px;
        }

        @include breakpoint(mobile) {
            margin-block: 30px 12px;
        }
    }
}