.map {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
}

.mapboxgl-marker {
    cursor: pointer;
}

.user-marker {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #314ccd;
    cursor: grab;
}