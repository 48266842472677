@import "../../variable.scss";

.vendorDetail {
  &_sc {
    .gap_p {
      @include breakpoint(ipad) {
        --gap_y: 50px;
        flex-direction: column-reverse;
      }

      @include breakpoint(mobileSmall) {
        --gap_y: 40px;
      }
    }

    .left_s {
      width: 27.5%;

      @include breakpoint(ipad) {
        width: 100%;
      }
    }

    .right_s {
      width: 72.5%;

      @include breakpoint(ipad) {
        width: 100%;
      }
    }
  }

  &_list {
    background: $c_white;
    box-shadow: 0px 2px 66px #0000001a;
    border-radius: 20px;
    position: sticky;
    top: 85px;
    max-height: calc(100vh - 100px);
    overflow: auto;

    @include breakpoint(ipadLandsacpe) {
      border-radius: 10px;
    }

    @include breakpoint(ipad) {
      position: initial;
      max-height: initial;
    }

    @include breakpoint(mobile) {
      border-radius: 5px;
    }

    h2 {
      color: $c_black;
      padding: 35px 25px 20px;
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
      background-color: $c_white;
      z-index: 1;

      @include breakpoint(desktop) {
        padding: 25px 20px 15px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 22px;
        padding: 20px 15px 15px;
      }
    }

    ul {
      @include breakpoint(ipad) {
        padding-bottom: 10px;
      }

      li {
        color: #121212;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding: 15px 25px;
        gap: 25px;
        width: 100%;
        cursor: pointer;
        transition: $transition;

        @include breakpoint(desktop) {
          font-size: 17px;
          padding: 10px 20px;
          gap: 15px;
        }

        @include breakpoint(ipadLandsacpe) {
          font-size: 16px;
          padding: 10px 15px;
          gap: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 0;
          border-bottom: 1px solid #7070701a;
        }

        figure {
          min-width: 54px;
          max-width: 54px;
          height: 54px;
          border-radius: 50%;
          background-color: #f5f5f5;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;

          @include breakpoint(desktop) {
            min-width: 50px;
            max-width: 50px;
            height: 50px;
          }

          @include breakpoint(ipadLandsacpe) {
            min-width: 42px;
            max-width: 42px;
            height: 42px;
          }

          img {
            max-width: 35px;
            max-height: 35px;

            @include breakpoint(ipadLandsacpe) {
              max-width: 25px;
              max-height: 25px;
            }
          }
        }

        &:hover {
          color: $c_primary;
        }
      }
    }
  }

  &_content {
    .top_box {
      padding: 20px 30px;
      background-color: #697585;
      border-radius: 10px;
      margin-bottom: 35px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      height: 300px;

      @include breakpoint(desktop) {
        margin-bottom: 30px;
      }

      @include breakpoint(ipadLandsacpe) {
        padding: 20px;
      }

      @include breakpoint(mobile) {
        padding: 30px 15px 35px;
      }

      .d_flex {
        display: flex;
        align-items: center;
        gap: 100px;

        @include breakpoint(desktop) {
          gap: 80px;
        }

        @include breakpoint(ipadLandsacpe) {
          gap: 40px;
        }

        figure {
          min-width: 245px;
          max-width: 245px;

          @include breakpoint(desktop) {
            min-width: 220px;
            max-width: 220px;
          }

          @include breakpoint(ipadLandsacpe) {
            min-width: 180px;
            max-width: 180px;
          }

          @include breakpoint(mobile) {
            display: none;
          }

          img {
            width: 100%;
          }
        }
      }

      .s_hd {
        max-width: 420px;

        @include breakpoint(mobile) {
          max-width: 100%;
          text-align: center;
        }

        h2 {
          margin-bottom: 10px;
        }

        p {
          font-size: 26px;

          @include breakpoint(desktop) {
            font-size: 24px;
          }

          @include breakpoint(ipadLandsacpe) {
            font-size: 20px;
          }

          @include breakpoint(mobile) {
            font-size: 16px;
          }
        }

        .btn {
          margin-top: 30px;

          @include breakpoint(desktop) {
            margin-top: 25px;
          }

          @include breakpoint(ipadLandsacpe) {
            margin-top: 20px;
          }

          @include breakpoint(mobile) {
            margin-top: 20px;
          }
        }
      }
    }

    .hd_3 {
      margin-bottom: -20px;

      @include breakpoint(desktop) {
        margin-bottom: -15px;
      }

      @include breakpoint(mobileSmall) {
        margin-bottom: 0;
      }

      &.d_flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 30px;

        @include breakpoint(mobileSmall) {
          flex-direction: column;
          gap: 15px;
        }
      }
    }

    h3 {
      color: $c_black;
      font-size: 24px;
      margin-bottom: 15px;
      margin-top: 35px;

      @include breakpoint(desktop) {
        font-size: 22px;
        margin-top: 30px;
      }

      @include breakpoint(ipadLandsacpe) {
        font-size: 20px;
      }

      @include breakpoint(mobile) {
        font-size: 18px;
      }
    }
  }
}
