@import "../../variable.scss";

.page_marketplaceVendors {
    .home_vendor_sc.u_spc {
        padding-top: 0;
    }
}

.mpVendors_hero {
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center right;
    background-repeat: no-repeat;
    min-height: 635px;

    @include breakpoint(ipadLandsacpe) {
        min-height: 565px;
    }

    @include breakpoint(ipad) {
        min-height: 350px;
    }

    @include breakpoint(mobile) {
        min-height: 300px;
    }

    @include breakpoint(mobileSmall) {
        background-position: center left;
    }

    :is(h1, p) {
        color: $c_white;
    }

    h1 {
        margin-bottom: 15px;
    }

    .btn {
        margin-top: 30px;
    }
}