@import "../../variable.scss";

.categoryDetail_sc {
    >figure {
        display: flex;
        aspect-ratio: 1/0.3;
        background-color: #f9f9f9;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .custom_tabs {
        margin-top: 40px;

        @include breakpoint(ipadLandsacpe) {
            margin-top: 25px;
        }
            
        @include breakpoint(mobile) {
            margin-top: 20px;
        }

        .custom_tabs_links {
            @include breakpoint(desktop) {
                margin-bottom: 0;
            }
        }
    }
}

.cat_content {
    margin-top: -110px;

    @include breakpoint(desktop) {
        margin-top: -100px;
    }

    @include breakpoint(ipadLandsacpe) {
        margin-top: -75px;
    }

    @include breakpoint(mobile) {
        margin-top: -60px;
    }

    @include breakpoint(mobileSmall) {
        margin-top: -50px;
    }

    &_figure {
        width: 220px;
        aspect-ratio: 1/1;
        display: inline-flex;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 30px;
        background-color: $c_white;
        border: 1px solid rgba(0, 0, 0, 0.05);

        @include breakpoint(desktop) {
            width: 200px;
            border-radius: 15px;
        }

        @include breakpoint(ipadLandsacpe) {
            width: 150px;
            border-radius: 10px;
            margin-bottom: 20px;
        }

        @include breakpoint(mobile) {
            width: 120px;
        }

        @include breakpoint(mobileSmall) {
            width: 100px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    >p {
        font-size: 16px;
        font-weight: 500;

        @include breakpoint(ipad) {
            font-size: 14px;
        }
    }

    .hd_3 {
        h1 {
            &:not(:last-child) {
                padding-bottom: 15px;
            }

            +p {
                font-size: 20px;
                font-weight: 500;
            }
        }
    }

    .list_datail {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px 45px;

        @include breakpoint(desktop) {
            margin-top: 15px;
            gap: 15px 35px;
        }

        @include breakpoint(ipadLandsacpe) {
            gap: 10px 30px;
        }

        @include breakpoint(mobile) {
            gap: 8px 25px;
        }

        li {
            margin: 0;
            font-size: 20px;
            font-weight: 600;

            @include breakpoint(desktop) {
                font-size: 18px;
            }

            @include breakpoint(ipadLandsacpe) {
                font-size: 16px;
                font-weight: 700;
            }

            @include breakpoint(mobile) {
                font-size: 14px;
            }
        }
    }

    .boxed_detail {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        gap: 12px;

        @include breakpoint(ipadLandsacpe) {
            gap: 10px;
        }

        li {
            margin: 0;
            padding: 20px 15px;
            background-color: $c_white;
            box-shadow: 0px 2px 56px #0000001A;
            border-radius: 10px;
            text-align: center;
            min-width: 210px;

            @include breakpoint(desktop) {
                min-width: 200px;
            }
            
            @include breakpoint(ipad) {
                min-width: 170px;
            }
            
            @include breakpoint(mobile) {
                min-width: 150px;
                border-radius: 5px;
            }

            @include breakpoint(mobileSmall) {
                min-width: 140px;
                padding: 15px 15px 18px;
            }

            h2 {
                font-size: 36px;
                margin-bottom: 5px;

                @include breakpoint(desktop) {
                    font-size: 34px;
                }

                @include breakpoint(ipadLandsacpe) {
                    font-size: 30px;
                }

                @include breakpoint(mobile) {
                    font-size: 25px;
                }
            }

            p {
                font-size: 22px;
                font-weight: 600;
                line-height: 1.2;

                @include breakpoint(desktop) {
                    font-size: 20px;
                }

                @include breakpoint(ipadLandsacpe) {
                    font-size: 18px;
                }

                @include breakpoint(ipad) {
                    font-size: 16px;
                }

                @include breakpoint(mobile) {
                    font-size: 14px;
                }
            }
        }
    }
}