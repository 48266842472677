@import "../../variable.scss";

.category_listing {
    &:has(.gap_m:empty) {
        display: none;
    }

    .category_listing_single {
        width: calc(100% / 4 - var(--gap_y));

        @include breakpoint(ipadLandsacpe) {
            width: calc(100% / 3 - var(--gap_y));
        }

        @include breakpoint(mobile) {
            width: calc(100% / 2 - var(--gap_y));
        }
    }

    .gap_m {
        --gap_x: 20px;
        --gap_y: 20px;

        @include breakpoint(mobile) {
            --gap_x: 15px;
            --gap_y: 15px;
        }

        @include breakpoint(mobileSmall) {
            --gap_x: 10px;
            --gap_y: 10px;
        }
    }

    &_single {
        padding-bottom: 20px;
        box-shadow: 0px 2px 56px #0000001A;
        background-color: $c_white;
        transition: $transition;
        border-radius: 20px;
        position: relative;
        overflow: hidden;

        @include breakpoint(ipadLandsacpe) {
            border-radius: 15px;
        }

        @include breakpoint(ipad) {
            padding-bottom: 15px;
            border-radius: 10px;
        }

        @include breakpoint(mobile) {
            border-radius: 5px;
        }

        @include breakpoint(mobileSmall) {
            padding-bottom: 12px;
        }

        figure {
            width: 100%;
            display: flex;
            aspect-ratio: 1/1;
            transition: $transition;
            background-color: $c_white;
            margin-bottom: 15px;
            overflow: hidden;

            @include breakpoint(ipad) {
                margin-bottom: 12px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                transition: $transition;
            }
        }

        :is(h3, p) {
            padding-inline: 18px;
            transition: $transition;

            @include breakpoint(ipad) {
                padding-inline: 15px;
            }

            @include breakpoint(mobileSmall) {
                padding-inline: 10px;
            }
        }

        h3 {
            font-size: 20px;
            line-height: 1.5;

            @include breakpoint(ipad) {
                font-size: 18px;
            }

            @include breakpoint(mobile) {
                font-size: 16px;
            }

            @include breakpoint(mobileSmall) {
                font-size: 14px;
            }

            span {
                width: 100%;
                font-size: 16px;
                font-weight: 500;
                margin-top: 5px;
                @include content_limit(1);

                @include breakpoint(ipad) {
                    font-size: 14px;
                    margin-top: 2px;
                }

                @include breakpoint(mobileSmall) {
                    font-size: 12px;
                }
            }
        }

        &:hover {
            cursor: pointer;
            background-color: $c_primary;

            figure {
                img {
                    transform: scale(1.05);
                }
            }

            :is(h3, p) {
                color: $c_white;
            }
        }
    }
}

.ec_category_sc {
    .no_date_sc {
        padding-top: 30px;

        @include breakpoint(mobile) {
            padding-top: 20px;
        }
    }

    .s_head_flex {
        @include breakpoint(ipad) {
            flex-direction: column;
            text-align: center;
            gap: 20px;
        }

        .right_s {
            @include breakpoint(ipad) {
                gap: 10px;
                justify-content: center;
            }

            @include breakpoint(mobileSmall) {
                width: 100%;

                .search_bar {
                    width: 100%;

                    .form_control {
                        input {
                            min-width: 1px;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}