@import "../../variable.scss";

.cart {
    &_box {
        padding: 35px 25px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 66px #0000001A;
        border-radius: 20px;

        @include breakpoint(ipadLandsacpe) {
            border-radius: 15px;
            padding: 30px 20px;
        }

        @include breakpoint(ipad) {
            border-radius: 10px;
        }

        @include breakpoint(mobile) {
            border-radius: 5px;
            padding: 20px 15px;
        }

        >.hd_4 {
            margin-bottom: 30px;
        }

        :is(.left_s, .right_s) {
            width: 50%;

            @include breakpoint(mobileSmall) {
                width: 100%;
            }
        }

        .left_s {
            figure {
                max-width: 485px;

                img {
                    width: 100%;
                    border-radius: 20px;

                    @include breakpoint(ipadLandsacpe) {
                        border-radius: 15px;
                    }
    
                    @include breakpoint(ipad) {
                        border-radius: 10px;
                    }
    
                    @include breakpoint(mobile) {
                        border-radius: 5px;
                    }
                }
            }
        }

        .right_s {
            p {
                font-size: 20px;
                margin-bottom: 25px;

                @include breakpoint(ipadLandsacpe) {
                    font-size: 18px;
                    margin-bottom: 20px;
                }

                @include breakpoint(ipad) {
                    font-size: 16px;
                }

                @include breakpoint(mobile) {
                    font-size: 14px;
                    margin-bottom: 15px;
                }
            }

            h3 {
                font-size: 24px;
                word-spacing: 10px;
                margin-bottom: 40px;

                @include breakpoint(ipadLandsacpe) {
                    font-size: 22px;
                    margin-bottom: 30px;
                }

                @include breakpoint(ipad) {
                    font-size: 20px;
                    margin-bottom: 20px;
                }

                @include breakpoint(mobile) {
                    font-size: 18px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}